import dashboard from './dashboard';
import other from './other';
import managers from './managers';

// ==============================|| MENU ITEMS ||============================== //
const userId = localStorage.getItem('userId');
const managerIds = ['1', '5', '896'];

const menuItems = {
    items: managerIds.includes(userId) ? [dashboard, other, managers] : [dashboard, other]
};

export default menuItems;
